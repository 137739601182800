<template>
    <!-- eslint-disable max-len -->
    <div
        class="operator-SlotStockIndicator rounded-full overflow-hidden relative h-6 mt-2 w-full mx-auto"
        :class="barColor === 'bg-progressbar-red' ? 'bg-progressbar-red bg-opacity-25' : 'bg-gray-200 dark:bg-night-01dp'"
    >
        <div
            class="operator-SlotStockIndicator_Percentage text-sm leading-none"
            :class="barColor === 'bg-progressbar-red' ? 'text-progressbar-red' : 'text-white'"
        >
            <span
                >{{ stock }}/<span class="opacity-75">{{ capacity }}</span></span
            >
        </div>

        <div class="rounded-full h-full" :style="`width: ${percentage}%`" :class="barColor"></div>
    </div>
</template>

<script>
export default {
    props: {
        percentage: Number,
        stock: Number,
        capacity: Number,
    },

    computed: {
        barColor() {
            if (this.percentage <= 25) {
                return 'bg-progressbar-red';
            }

            if (this.percentage > 25 && this.percentage <= 75) {
                return 'bg-progressbar-orange';
            }

            return 'bg-progressbar-green';
        },
    },
};
</script>

<style lang="scss" scoped>
.operator-SlotStockIndicator {
    &_Percentage {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
