<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" :allow-close="!refillReference && !initializingRefill" class="operator-OperatingDrawer z-40">
        <div v-if="!showRefillErrorModal && machineOperatorData" class="operator-OperatingDrawer_Shelves grid row-gap-4 mt-6 pb-8">
            <MachineShelve
                v-for="shelve in machineOperatorData.shelves"
                :key="shelve.position"
                :shelve="shelve"
                :refill-reference="refillReference"
            />
        </div>

        <div v-if="showRefillErrorModal">
            <div class="text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis mb-4">
                <h1 v-html="$t('operator.refill-error-modal.title')"></h1>
            </div>

            <p
                class="text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis"
                v-html="$t('operator.refill-error-modal.description')"
            ></p>

            <div class="mt-10 grid row-gap-2">
                <AppButton type="secondary" v-if="refillReference" @click="unlockMachine" size="small" icon="unlock" width="full">
                    {{ $t('operator.buttons.click-to-unlock') }}
                </AppButton>

                <AppButton v-if="refillReference" @click="finishRefill" size="small" icon="lock" width="full">{{
                    $t('operator.buttons.click-to-stop')
                }}</AppButton>
            </div>
        </div>

        <div v-if="!showRefillErrorModal" class="operator-OperatingDrawer_Actions flex flex-col gap-2">
            <AppButton v-if="!refillReference" @click="initiateNewRefill" size="small" icon="unlock" width="full">{{
                $t('operator.buttons.click-to-unlock')
            }}</AppButton>

            <AppButton v-if="refillReference" @click="finishRefill" size="small" icon="lock" width="full">{{
                $t('operator.buttons.click-to-stop')
            }}</AppButton>
        </div>
    </BaseDrawer>
</template>

<script>
/* eslint-disable max-len */
import AppButton from '@/components/Interface/AppButton.vue';
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import BaseModal from '@/components/Interface/BaseModal.vue';
import MachineShelve from '@/components/Operator/MachineShelve.vue';
import useMachine from '@/composables/useMachine';
import { onBeforeMount, ref } from '@vue/composition-api';

export default {
    name: 'OperatingDrawer',

    components: {
        BaseDrawer,
        BaseModal,
        MachineShelve,
        AppButton,
    },

    setup(_, { root, emit }) {
        const { fetchMachineData, code, machineOperatorData, machineStatus } = useMachine();
        const refillReference = ref(null);
        const initializingRefill = ref(false);
        const showRefillErrorModal = ref(false);

        async function fetchInitialMachineStatus() {
            try {
                const statusResponse = await root.$http.get(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}/status`);

                machineStatus.value = statusResponse.data.status;
            } catch (error) {
                console.log(error);
            }
        }

        const fetchMachineOperatorData = async () => {
            try {
                const response = await root.$http.get(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}`);
                machineOperatorData.value = response.data.data;
            } catch (error) {
                root.$router.push({ name: 'ServerError' });
            }
        };

        onBeforeMount(async () => {
            void fetchInitialMachineStatus();
            void fetchMachineOperatorData();
        });

        const unlockMachine = async () => {
            try {
                await root.$http.get(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}/unlock`);

                root.$toast.open({
                    message: root.$t('operator.notifications.door-unlocked'),
                    type: 'success',
                });
            } catch (error) {
                root.$toast.open({
                    message: root.$t('server-error.heading'),
                    type: 'error',
                });
            }
        };

        /**
         * Method to initate a new refill
         */
        const initiateNewRefill = async () => {
            initializingRefill.value = true;

            try {
                const response = await root.$http.post(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}/init-refill`);
                refillReference.value = response.data.data.reference;

                initializingRefill.value = false;

                root.$toast.open({
                    message: root.$t('operator.notifications.door-unlocked'),
                    type: 'success',
                });
            } catch (error) {
                root.$router.push({ name: 'ServerError' });
            }
        };

        /**
         * Method to finish refill
         */
        const finishRefill = async () => {
            // first check if the machine is operational again
            const statusResponse = await root.$http.get(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}/status`);

            if (statusResponse.data.status !== 'operational') {
                // show modal
                if (!showRefillErrorModal.value) {
                    showRefillErrorModal.value = true;
                } else {
                    root.$toast.open({
                        message: root.$t('operator.refill-error-modal.error'),
                        type: 'error',
                    });
                }

                return;
            }

            try {
                const requestUrl = `${process.env.VUE_APP_OPERATOR_API_URL}/refill/${refillReference.value}`;
                await root.$http.put(requestUrl, {
                    machine: code.value,
                });

                root.$toast.open({
                    message: root.$t('operator.notifications.saved'),
                    type: 'success',
                });

                refillReference.value = null;

                // Refetch machine data for new stock values
                fetchMachineData(code.value);

                emit('close');

                this.$emit('close');
            } catch (error) {
                console.log(error);
            }
        };

        return {
            machineOperatorData,
            refillReference,
            initiateNewRefill,
            unlockMachine,
            initializingRefill,
            finishRefill,
            machineStatus,
            showRefillErrorModal,
        };
    },
};
</script>

<style lang="scss" scoped>
.operator-OperatingDrawer {
    &_Actions {
        position: sticky;
        width: 100%;
        bottom: 0;
        z-index: 20;
    }
}
</style>
