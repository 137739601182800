<template>
    <div>
        <!-- eslint-disable max-len -->
        <BaseDrawer v-if="!operatingDrawerActive && !fridgeOnboardingActive" @close="$emit('close')" class="MachineOptionsDrawer">
            <div class="grid row-gap-4 pb-8">
                <!-- Default operator -->
                <button @click="toggleOperatingDrawer" v-if="canOperate && machine.type === 'vending'">
                    <AppMenuItem :label="$t('order.options.refill')">
                        <template #icon><AppIcon icon-name="redo" :width="24" :height="24"/></template>
                    </AppMenuItem>
                </button>

                <!-- Drinks operator -->
                <RouterLink :to="{ name: 'OperateDrinks' }" v-if="canOperate && machine.type === 'drinks'">
                    <AppMenuItem :label="$t('order.options.refill')">
                        <template #icon><AppIcon icon-name="redo" :width="24" :height="24"/></template>
                    </AppMenuItem>
                </RouterLink>

                <!-- Onboarding fridge -->
                <button v-if="machine.type === 'fridge'" @click="toggleFridgeOnboarding">
                    <AppMenuItem :label="$t('order.options.info')">
                        <template #icon><AppIcon icon-name="help" :width="24" :height="24"/></template>
                    </AppMenuItem>
                </button>

                <!-- Instruction video -->
                <button v-if="canOperate" @click="showVideoOverlay = true">
                    <AppMenuItem :label="$t('order.options.watch-instructions')">
                        <template #icon><AppIcon icon-name="help" :width="24" :height="24"/></template>
                    </AppMenuItem>
                </button>

                <!-- Disconnect machine -->
                <button @click="disconnect">
                    <AppMenuItem :label="$t('order.options.disconnect')">
                        <template #icon><AppIcon icon-name="disconnect" :width="24" :height="24"/></template>
                    </AppMenuItem>
                </button>

                <p
                    v-if="!canOperate"
                    class="text-center w-11/12 mx-auto text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis font-light"
                >
                    {{ $t('order.options.refill-rights') }}
                </p>
            </div>
        </BaseDrawer>

        <portal to="drawers" :order="2">
            <OperatingDrawer v-if="operatingDrawerActive" @close="toggleOperatingDrawer" />

            <FridgeOnboarding v-if="fridgeOnboardingActive" @close="toggleFridgeOnboarding" />

            <!-- Video overlay -->
            <div v-if="showVideoOverlay" class="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center">
                <button
                    @click="showVideoOverlay = false"
                    class="absolute rounded-full w-12 h-12 bg-day-08dp dark:bg-night-04dp flex items-center justify-center"
                    style="top: 16px; right: 16px"
                >
                    <AppIcon icon-name="exit" :width="24" :height="24" />
                </button>

                <iframe
                    :src="`https://player.vimeo.com/video/${getVideoIdByMachineType}`"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                ></iframe>
            </div>
        </portal>
    </div>
</template>

<script>
import FridgeOnboarding from '@/components/Fridge/Onboarding.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import AppMenuItem from '@/components/Interface/AppMenuItem.vue';
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import OperatingDrawer from '@/components/Operator/OperatingDrawer.vue';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';
import router from '@/router';
import { computed, ref } from '@vue/composition-api';

export default {
    name: 'MachineOptionsDrawer',

    components: {
        BaseDrawer,
        OperatingDrawer,
        FridgeOnboarding,
        AppMenuItem,
        AppIcon,
    },

    setup() {
        const { disconnectMachine, machine } = useMachine();
        const { resetOrderState } = useOrder();

        /**
         * Method to disconnect from machine and navigate back to home
         * @returns {void}
         */
        const disconnect = () => {
            disconnectMachine();
            resetOrderState();

            router.push({ name: 'HomeScreen' });
        };

        const operatingDrawerActive = ref(false);

        const toggleOperatingDrawer = () => {
            operatingDrawerActive.value = !operatingDrawerActive.value;
        };

        const fridgeOnboardingActive = ref(false);

        const toggleFridgeOnboarding = () => {
            fridgeOnboardingActive.value = !fridgeOnboardingActive.value;
        };

        const showVideoOverlay = ref(false);

        /* eslint-disable indent */
        const getVideoIdByMachineType = computed(() => {
            if (machine.value.type === 'drinks') {
                return '922361259';
            }

            return '922364581';
        });

        return {
            disconnect,
            operatingDrawerActive,
            toggleOperatingDrawer,
            canOperate: machine.value.can_operate,
            machine,
            fridgeOnboardingActive,
            toggleFridgeOnboarding,
            showVideoOverlay,
            getVideoIdByMachineType,
        };
    },
};
</script>

<style lang="scss" scoped>
.MachineOptionsDrawer {
}
</style>
